<template>
  <CustomFormBaseElement
    :value="value"
    @input="handleCustomFormBaseElementInput"
  >
    <b-row>
      <b-col cols="12">
        <h5>Opciones</h5>
      </b-col>
      <b-col
        v-for="(option, index) in value.options"
        :key="index"
        cols="12"
        class="px-2"
      >
        <b-row align-v="center">
          <b-col sm="4">
            <b-form-group
              :label="$t('Label')"
              :label-for="'option-label-' + index"
            >
              <b-form-input
                :id="'option-label-' + index"
                :value="option.label"
                :placeholder="$t('Label')"
                @input="updateOption($event, index, 'label')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              :label="$t('Value')"
              :label-for="'option-value-' + index"
            >
              <b-form-input
                :id="'option-value-' + index"
                :value="option.value"
                :placeholder="$t('Value')"
                @input="updateOption($event, index, 'value')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-link
              class="mr-1 text-danger cursor-pointer"
              @click="removeOption(index)"
            >
              <feather-icon
                icon="TrashIcon"
                size="20"
              />
            </b-link>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        class="px-2"
      >
        <b-button
          variant="primary"
          @click="addOption"
        >
          {{ $t('AñadirOpcion') }}
        </b-button>
      </b-col>
    </b-row>
  </CustomFormBaseElement>
</template>

<script>
import CustomFormBaseElement from '@/components/customForms/element/CustomFormBaseElement.vue'

export default {
  name: 'CustomFormSelectElement',
  components: { CustomFormBaseElement },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    options() {
      if (!this.value.options) {
        return []
      }

      return [...this.value.options]
    },
  },
  methods: {
    handleCustomFormBaseElementInput(value, key) {
      this.updateModel(value, key)
    },
    addOption() {
      const { options } = this
      options.push({
        label: '',
        value: '',
      })
      this.updateModel(options, 'options')
    },
    updateOption(value, optionIndex, key) {
      const { options } = this
      options[optionIndex][key] = value
      this.updateModel(options, 'options')
    },
    removeOption(optionIndex) {
      const { options } = this
      options.splice(optionIndex, 1)
      this.updateModel(options, 'options')
    },
    updateModel(value, key) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}

</script>
