<template>
  <v-select
    :value="value"
    :options="elementsToSelect"
    :filterable="false"
    :clearable="false"
    :disabled="disabled"
    label="name"
    @input="$emit('input', $event)"
  >
    <template #selected-option="option">
      <span v-if="option.name">
        <feather-icon
          class="mr-1"
          :icon="getIcon(option.alias)"
          size="20"
        />
        {{ $t(`Forms.${option.name}`) }}
      </span>
    </template>
    <template v-slot:option="option">
      <feather-icon
        class="mr-1"
        :icon="getIcon(option.alias)"
        size="20"
      />
      {{ $t(`Forms.${option.name}`) }}
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'CustomFormElementsSelect',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      selectElements: 'forms/getSelectElements',
      role: 'auth/getRole',
    }),
    elementsToSelect () {
      const adminElements = this.selectElements.filter(({ alias }) => {
        return !['hidden', 'title_input'].includes(alias)
      })

      return this.role === 'admin_cliente' ? adminElements : this.selectElements
    }
  },
  methods: {
    getIcon(alias) {
      if(alias === 'check') {
        return 'CheckCircleIcon'
      }
      if(alias === 'archive') {
        return 'UploadIcon'
      }
      if(alias === 'text_area') {
        return 'AlignLeftIcon'
      }
      if(alias === 'signature') {
        return 'UnderlineIcon'
      }
      if(alias === 'hidden') {
        return 'EyeOffIcon'
      }
      if(alias === 'title_input') {
        return 'BoldIcon'
      }
      if(alias === 'text_input') {
        return 'TypeIcon'
      }
      if(alias === 'text_block') {
        return 'AlignJustifyIcon'
      }
      return ''
    }
  },
}
</script>

<style scoped>
</style>
