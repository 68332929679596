<template>
  <div class="light">
    <b-row align-v="center" class="pb-1">
      <b-col sm="12" md="10">
        <h4 class="mb-0">
          {{ $t("ElementosFormulario") }}
        </h4>
      </b-col>
    </b-row>
    <draggable
      :list="value"
      :group="{ name: 'formElements', pull: 'clone', put: false }"
      draggable=".item"
    >
      <b-card
        v-for="(section, sectionIndex) in value"
        :key="sectionIndex"
        no-body
        class="mb-1"
        :class="{ 'item cursor-move': sectionIndex !== 0 }"
      >
        <b-card
          v-show="
            role === 'admin_cliente'
              ? sectionIndex === 0
                ? false
                : true
              : true
          "
          :class="
            sectionIndex === 0
              ? 'bg-white'
              : handleCollapse[sectionIndex]
              ? 'bg-primary'
              : ''
          "
          :border-variant="sectionIndex === 0 ? '' : 'secondary'"
          class="mb-0"
          @click="handleCollapse[sectionIndex] = !handleCollapse[sectionIndex]"
        >
          <!-- no-body -->
          <div
            :class="
              sectionIndex === 0
                ? ''
                : handleCollapse[sectionIndex]
                ? 'text-white'
                : ''
            "
            class="d-flex justify-content-between"
          >
            <div class="d-flex" :class="{ 'cursor-move': sectionIndex !== 0 }">
              <feather-icon
                v-if="sectionIndex !== 0"
                icon="MoveIcon"
                size="25"
              />
              <span class="ml-1">
                {{ section.title }}
              </span>
            </div>
            <FeatherIcon
              v-if="handleCollapse[sectionIndex]"
              icon="ChevronDownIcon"
              :size="'18'"
            />
            <FeatherIcon v-else icon="ChevronUpIcon" :size="'18'" />
          </div>
        </b-card>
        <b-collapse
          v-model="handleCollapse[sectionIndex]"
          accordion="my-accordion"
          role="tabpanel"
          class="bg-light"
        >
          <b-row v-if="sectionIndex !== 0" class="mx-2 pt-2" align-v="center">
            <b-col class="d-flex align-items-center">
              <b-form-input v-model="section.title" :disabled="!section.edit" />
              <b-link
                class="ml-1 text-danger cursor-pointer"
                @click="handleDeleteSection(sectionIndex)"
              >
                <feather-icon icon="TrashIcon" size="20" />
              </b-link>
            </b-col>
            <b-col v-if="!section.edit" class="d-flex justify-content-end">
              <b-link class="cursor-pointer" @click="section.edit = true">
                <feather-icon icon="EditIcon" size="20" />
              </b-link>
              <b-link
                class="ml-1 text-danger cursor-pointer"
                @click="handleDeleteSection(sectionIndex)"
              >
                <feather-icon icon="TrashIcon" size="20" />
              </b-link>
            </b-col>
          </b-row>
          <b-row class="p-1">
            <b-col cols="12">
              <template v-for="(element, elementIndex) in section.elements">
                <div>
                  <hr :key="elementIndex + '-separator'" />
                  <b-list-group-item
                    :key="elementIndex"
                    class="custom-form-list-item"
                    :class="{ disabled: element.default || !section.edit }"
                    :style="
                      element.default || !section.edit
                        ? 'background: #f8f9fa;'
                        : ''
                    "
                  >
                    <CustomFormElement
                      :disabled="!section.edit"
                      :value="element"
                      @input="updateElement($event, elementIndex, sectionIndex)"
                      @clone-element="
                        handleCloneElement(element, elementIndex, sectionIndex)
                      "
                      @remove-element="
                        handleRemoveElement(elementIndex, sectionIndex)
                      "
                      @order-up="
                        handleOrderChange(
                          elementIndex,
                          elementIndex - 1,
                          sectionIndex
                        )
                      "
                      @order-down="
                        handleOrderChange(
                          elementIndex,
                          elementIndex + 1,
                          sectionIndex
                        )
                      "
                    />
                  </b-list-group-item>
                </div>
              </template>
              <hr class="mt-2" />
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                v-if="section.edit"
                type="submit"
                variant="primary"
                class="mr-2"
                @click="handleAddElement(sectionIndex)"
              >
                {{ $t("Añadir elemento") }}
              </b-button>
              <div
                v-b-tooltip
                :title="!enabledSaveButtonGeneral ? $t('AddFormInfo') : ''"
              >
                <b-button
                  v-if="section.edit"
                  type="submit"
                  variant="primary"
                  :disabled="!enabledSaveButtonGeneral"
                  @click="$emit('handleSubmit')"
                >
                  {{ $t("Guardar") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </b-card>
    </draggable>
    <hr class="mt-2" />
    <b-row align-h="end" class="pb-1">
      <b-col sm="12" md="4" class="d-flex justify-content-end">
        <b-tooltip
          v-if="!enabledSaveButtonGeneral"
          triggers="hover"
          target="save-button"
          :title="$t('AddFormInfo')"
        />
        <div id="save-button">
          <b-button
            class="mr-1"
            type="submit"
            variant="primary"
            :disabled="!enabledSaveButtonGeneral"
            @click="$emit('handleSubmit')"
          >
            {{ $t("Guardar") }}
          </b-button>
        </div>
        <b-button type="submit" variant="primary" @click="handleAddSection">
          {{ $t("AddSection") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CustomFormElement from "@/components/customForms/element/CustomFormElement.vue";
import { timestamp } from "@vueuse/core";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "CustomFormElementList",
  components: {
    CustomFormElement,
    draggable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      handleCollapse: {},
    };
  },
  computed: {
    ...mapGetters({
      role: "auth/getRole",
      selectElements: "forms/getSelectElements",
    }),
    enabledSaveButtonGeneral() {
      const noDefaultElements = JSON.parse(
        JSON.stringify(
          this.value.filter(({ elements }) => !elements[0]?.default)
        )
      );
      return (
        noDefaultElements.length > 0 &&
        noDefaultElements.every(
          (item) => Array.isArray(item.elements) && item.elements.length > 0
        )
      );
    },
  },
  methods: {
    ...mapActions({
      getSelectElements: "forms/getSelectElements",
    }),
    handleAddElement(sectionIndex) {
      const model = [...this.value];

      model[sectionIndex].elements.push({
        alias: "text_input",
        name: "Text",
        id: 8,
        order: model[sectionIndex].elements.length + 1,
        config: {},
        default: false,
        observations: false,
      });

      this.$emit("input", model);
    },
    updateElement(element, elementIndex, sectionIndex) {
      const model = [...this.value];

      model[sectionIndex].elements[elementIndex] = element;
      this.$emit("input", model);
    },
    handleCloneElement(element, elementIndex, sectionIndex) {
      const model = [...this.value];

      const newElement = {
        alias: element.alias,
        name: element.name,
        id: element.id,
        order: element.order + 1,
        config: element.config,
        default: false,
        observations: false,
      };

      model[sectionIndex].elements.splice(elementIndex, 0, newElement);

      model[sectionIndex].elements = model[sectionIndex].elements.map(
        (item, index) => {
          item.order = index + 1;
          return item;
        }
      );

      this.$emit("input", model);
    },
    handleRemoveElement(elementIndex, sectionIndex) {
      const model = [...this.value];
      model[sectionIndex].elements.splice(elementIndex, 1);
      this.$emit("input", model);
    },
    handleOrderChange(currentIndex, targetIndex, sectionIndex) {
      if (
        targetIndex < 0 ||
        targetIndex >= this.value[sectionIndex].elements.length ||
        this.value[sectionIndex].elements[targetIndex].default
      ) {
        return;
      }

      const model = [...this.value];

      model[sectionIndex].elements[targetIndex].order = currentIndex + 1;
      model[sectionIndex].elements[currentIndex].order = targetIndex + 1;

      const temp = model[sectionIndex].elements[targetIndex];

      model[sectionIndex].elements[targetIndex] =
        model[sectionIndex].elements[currentIndex];
      model[sectionIndex].elements[currentIndex] = temp;

      this.$emit("input", model);
    },
    handleAddSection() {
      const model = [...this.value];
      model.push({ title: this.$t("NewSection"), edit: true, elements: [] });
      this.$set(this.handleCollapse, model.length - 1, true);
      this.$emit("input", model);
    },
    handleDeleteSection(elementIndex) {
      const model = [...this.value];
      model.splice(elementIndex, 1);
      this.$emit("input", model);
    },
  },
  async mounted() {
    if (!this.selectElements || this.selectElements.length === 0) {
      await this.getSelectElements();
    }
  },
};
</script>

<style scoped lang="scss">
.custom-form-list-item {
  border-width: 0;
}
</style>
