<template>
  <CustomFormBaseElement
    :value="value"
    :question-input-visible="false"
    :summary-input-visible="false"
    :required-input-visible="false"
    :observations-input-visible="false"
    :show-form-visible="true"
    :disabled="disabled"
    @input="handleCustomFormBaseElementInput"
  >
    <template #beforeConfig>
      <quill-editor :options="editorOption" @input="updateModel($event, 'text')" :value="value.text"/>
    </template>
  </CustomFormBaseElement>
</template>

<script>
import CustomFormBaseElement from '@/components/customForms/element/CustomFormBaseElement.vue'
import { quillEditor } from "vue-quill-editor";
import quillMixin from "@/mixins/quillMixin"

export default {
  name: 'CustomFormTextAreaElement',
  components: { CustomFormBaseElement, quillEditor },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [quillMixin],
  methods: {
    handleCustomFormBaseElementInput(value, key) {
      this.updateModel(value, key)
    },
    updateModel(value, key) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}

</script>
