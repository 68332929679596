<template>
  <CustomFormBaseElement
    :value="value"
    :disabled="disabled"
    :multipleFormVisible="true"
    @input="handleCustomFormBaseElementInput"
  >
  <template #beforeConfig>
    <b-row>
      <b-col cols="12" class="pb-1">
        <label>Opciones</label>
      </b-col>
      <b-col
        v-for="(option, index) in value.options"
        :key="index"
        cols="12"
        class="px-2"
      >
        <b-row align-v="center">
          <b-col sm="4">
            <b-form-group
              :label="$t('Nombre')"
              :label-for="'option-label-' + index"
            >
              <b-form-input
                :id="'option-label-' + index"
                :value="option.label"
                :placeholder="$t('Nombre')"
                :disabled="disabled"
                @input="updateOption($event, index)"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="!disabled" cols="auto">
            <b-link
              class="mr-1 text-danger cursor-pointer"
              @click="removeOption(index)"
            >
              <feather-icon
                icon="TrashIcon"
                size="20"
              />
            </b-link>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="!disabled"
        cols="12"
        class="px-2"
      >
        <b-button
          variant="primary"
          @click="addOption"
        >
          {{ $t('AñadirOpcion') }}
        </b-button>
      </b-col>
    </b-row>
  </template>
  </CustomFormBaseElement>
</template>

<script>
import CustomFormBaseElement from '@/components/customForms/element/CustomFormBaseElement.vue'

export default {
  name: 'CustomFormCheckboxElement',
  components: { CustomFormBaseElement },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    options() {
      if (!this.value.options) {
        return []
      }

      return [...this.value.options]
    },
  },
  methods: {
    handleCustomFormBaseElementInput(value, key) {
      this.updateModel(value, key)
    },
    addOption() {
      const { options } = this
      options.push({
        label: '',
        value: '',
      })
      this.updateModel(options, 'options')
    },
    updateOption(value, optionIndex) {
      const { options } = this
      
      options[optionIndex].label = value
      options[optionIndex].value = value

      this.updateModel(options, 'options')
    },
    removeOption(optionIndex) {
      const { options } = this
      options.splice(optionIndex, 1)
      this.updateModel(options, 'options')
    },
    updateModel(value, key) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}

</script>
