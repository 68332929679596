<template>
  <CustomFormBaseElement
    :value="value"
    :disabled="disabled"
    @input="handleCustomFormBaseElementInput"
  />
</template>

<script>
import CustomFormBaseElement from '@/components/customForms/element/CustomFormBaseElement.vue'

export default {
  name: 'CustomFormSignatureElement',
  components: { CustomFormBaseElement },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleCustomFormBaseElementInput(value, key) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}

</script>
