<template>
  <b-row align-v="center">
    <b-col cols="12" class="mb-1">
      <slot name="beforeConfig" />
    </b-col>
    <b-col v-show="role !== 'admin_cliente'" cols="4">
      <b-form-group
        :label="$t('Identificador')"
        label-for="custom-form-element-identifier"
      >
        <b-form-input
          id="custom-form-element-identifier"
          :disabled="disabled"
          :value="value.identifier"
          :placeholder="$t('Identificador')"
          @input="$emit('input', $event, 'identifier')"
        />
      </b-form-group>
    </b-col>
    <!-- switches -->
    <b-col
      v-show="requiredInputVisible"
      cols="auto"
    >
      <b-form-checkbox
        :checked="value.required"
        :disabled="disabled"
        inline
        switch
        @input="$emit('input', $event, 'required')"
      >
        <span>{{ $t('Required') }}</span>
      </b-form-checkbox>
    </b-col>
    <b-col
      v-show="observationsInputVisible"
      cols="auto"
    >
      <b-form-checkbox
        :checked="value.observations"
        :disabled="disabled"
        inline
        switch
        @input="$emit('input', $event, 'observations')"
      >
        <span>{{ $t('Observations') }}</span>
      </b-form-checkbox>
    </b-col>
    <b-col
      v-show="showPdfVisible && role !== 'admin_cliente'"
      cols="auto"
    >
      <b-form-checkbox
        :checked="value.showpdf"
        inline
        :disabled="disabled"
        switch
        @input="$emit('input', $event, 'showpdf')"
      >
        <span>{{ $t('MostrarPDF') }}</span>
      </b-form-checkbox>
    </b-col>
    <b-col v-show="showFormVisible" cols="auto">
      <b-form-checkbox
        :checked="value.showForm"
        inline
        :disabled="disabled"
        switch
        @input="$emit('input', $event, 'showForm')"
      >
        <span>{{ $t('ShowForm') }}</span>
      </b-form-checkbox>
    </b-col>
    <b-col v-show="multipleFormVisible" cols="auto">
      <b-form-checkbox
        :checked="value.multiple"
        inline
        :disabled="disabled"
        switch
        @input="$emit('input', $event, 'multiple')"
      >
        <span>Multiple</span>
      </b-form-checkbox>
    </b-col>
    <b-col cols="12" class="mt-1">
      <slot />
    </b-col>
  </b-row>
</template>

<script>
import { timestamp } from '@vueuse/core'
import { mapGetters } from 'vuex'

export default {
  name: 'CustomFormBaseElement',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    questionInputVisible: {
      type: Boolean,
      default: true,
    },
    summaryInputVisible: {
      type: Boolean,
      default: true,
    },
    requiredInputVisible: {
      type: Boolean,
      default: true,
    },
    observationsInputVisible: {
      type: Boolean,
      default: true,
    },
    showFormVisible: {
      type: Boolean,
      default: false,
    },
    showPdfVisible: {
      type: Boolean,
      default: true,
    },
    multipleFormVisible: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
        role: 'auth/getRole',
    }),
  },
  mounted() {
    if (!this.value.identifier) {
      this.$emit('input', `formElement${timestamp()}`, 'identifier')
    }
  },
}
</script>
